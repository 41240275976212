import React, { useState, useEffect, useRef } from "react"
import _ from "lodash"
import { Grid, Typography, IconButton, Tooltip } from "@material-ui/core"
import { CloseRounded } from "@material-ui/icons"
import { useHistory } from "react-router-dom"
import Hierarchy from "../Hierarchy"
import Table from "./Table"
import api from "../../../services/masterData/locations/api"
import { productDataApiMethods } from "../../../services/masterData/products/api"
import * as Adm from "@adm"
import styled from "styled-components"
import { ConfirmationDialog } from "@features"
import * as ServiceAPI from "@services"
import SearchBar from "../../../components/AdmKit/SearchBar/SearchBar"
import * as constantsAPI from "@constants"
import { Helmet } from "react-helmet"
import NavigateBeforeOutlinedIcon from "@material-ui/icons/NavigateBeforeOutlined"
import NavigateNextOutlinedIcon from "@material-ui/icons/NavigateNextOutlined"
import { useDispatch, useSelector } from "react-redux"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const PageHeaderBreadcrumbKit = styled(Grid)`
  button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 18px;
    padding-right: 18px;
    max-width: unset;
  }
  margin-bottom: 24px;
  border-bottom: 1px #e0e0e0 solid;
  padding-bottom: 15px;
`
const HierarchyMapping = (props) => {
  const { isWriteAllowed, columns = null, pageName = "" } = props

  let history = useHistory()
  const userProfileDetails =
    useSelector((state) => state.adminReducer.userProfileDetails) || {}
  let productURL =
    props.lookupType === "product" ||
    history?.location?.pathname ===
      `/${userProfileDetails?.roleType}/master-data/products/productMapping`
  const isKC = history?.location?.pathname?.includes("edgeDevice")
  const {
    isLookUp = false,
    handleCloseLookUp = () => {},
    handleLookUpPrimaryAction = () => {},
    selectedItems = [],
    lookupType = productURL ? "product" : "location",
    titlePath = productURL ? "category_name" : "name",
    idPath = productURL ? "categoryId" : "hierarchyId",
    parentIdPath = productURL ? "mapped_parent_categories" : "parent",
    isInventory = false,
    hideCheckBoxAll = false,
    sdcFilter = [],
    dataUniqueIdPath = "_id",
    ShowCheckBox = true,
    locationBasedProduct = false,
  } = props
  const dataFetchMethod =
    lookupType === "product"
      ? productDataApiMethods.updateProductCategoryMappings
      : lookupType === "storage"
      ? api.updateStorageHierarchyMappings
      : api.updateLocationHierarchyMappings
  const [selectedHierarchyIds, setHierarchySelectedIds] = useState([])
  const [triggerDataFetch, setTriggerDataFetch] = useState({
    uncategorized: false,
    categorized: false,
  })
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [loading, setLoading] = useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [selectedDisAssociateItems, setSelectedDisAssociateItems] = useState([])
  const [SDCFilter, setSDCFilter] = useState(sdcFilter)
  const [locationTypeList, setlocationTypeList] = useState([])
  const [toggle, setToggle] = useState(false)

  const handleClick = () => {
    setToggle(!toggle)
  }

  const [dropDownSelectedItems, setDropdownSelectedItems] = useState({
    city: [],
    state: [],
    brand: [],
    manufacturer: [],
  })

  const initialDataState = {
    page: 1,
    showMore: false,
    limit: 10,
    searchTerm: "",
  }

  const dropdownList = {
    list: [],
    totalDocs: "",
    nextPage: null,
  }

  const [dropdownDataState, setDropdownDataState] = useState({
    city: initialDataState,
    state: initialDataState,
    brand: initialDataState,
    manufacturer: initialDataState,
  })

  const [dropDownList, setDropdownList] = useState({
    city: dropdownList,
    state: dropdownList,
    brand: dropdownList,
    manufacturer: dropdownList,
  })

  const [searchTerm, setSearchTerm] = React.useState("")
  const savedCallback = useRef()

  useEffect(() => {
    setSelectedDisAssociateItems(selectedDisAssociateItems)
  }, [selectedDisAssociateItems])

  const BreadcrumbList1 = [
    { name: "Master Data", disable: true },
    { name: "Products" },
    { name: "Product Mapping" },
  ]
  const BreadcrumbList = [
    { name: "Master Data", disable: true },
    { name: "Stores" },
    { name: "Stores Mapping" },
  ]

  const disAssociateConformDialog = async (selectedItems, id, callback) => {
    setAlert(false)
    savedCallback.current = callback
    if (selectedItems?.length === 0 || id === undefined) {
      await setAlertType("error")
      await setAlertMessage(
        lookupType === "product" ? "Select a Category" : "Select a Hierarchy"
      )
      await setAlert(true)
    } else {
      setSelectedDisAssociateItems(selectedItems)
      setOpenConfirmationDialog(true)
    }
  }

  useEffect(() => {
    setTriggerDataFetch({ uncategorized: false, categorized: true })
  }, [isLookUp, selectedDisAssociateItems])

  const handleCustomCloseRedirect = () => {
    let bodyData = {}
    if (lookupType === "product") {
      bodyData = {
        item_ids: _.map(selectedDisAssociateItems, (o) => _.get(o, "item_id")),
        category_Id: "",
      }
    } else {
      bodyData = {
        locationIds: _.map(selectedDisAssociateItems, (o) =>
          _.get(o, "locationId")
        ),
        hierarchyId: "",
      }
    }
    dataFetchMethod(bodyData)
      .then((resp) => {
        setTriggerDataFetch({ uncategorized: true, categorized: true })
        if (_.isFunction(savedCallback.current())) savedCallback.current()
        setAlertType(_.get(resp, "data.status", "error"))
        setAlertMessage(
          `Selected ${lookupType}(s) disassociated from "${
            selectedHierarchyIds[1] || "-"
          }" ${
            lookupType === "product" ? "category" : "hierarchy"
          } successfully`
        )
        setLoading(false)
        setAlert(true)
      })
      .catch((err) => {})
    setAlert(false)
    setLoading(true)
    setOpenConfirmationDialog(false)
    setSelectedDisAssociateItems([])
  }

  const handleMapping = async (selectedItems, id, callback) => {
    setAlert(false)
    if (selectedHierarchyIds.length === 0 || id === undefined) {
      await setAlertType("error")
      await setAlertMessage(
        lookupType === "product" ? "Select a Category" : "Select a Hierarchy"
      )
      await setAlert(true)
    } else {
      let bodyData = {}
      if (lookupType === "product") {
        bodyData = {
          item_ids: _.map(selectedItems, (o) => _.get(o, "item_id")),
          category_Id: id,
        }
      } else {
        bodyData = {
          locationIds: _.map(selectedItems, (o) => _.get(o, "locationId")),
          hierarchyId: id,
        }
      }
      dataFetchMethod(bodyData)
        .then((resp) => {
          setTriggerDataFetch({ uncategorized: true, categorized: true })
          if (_.isFunction(callback)) callback()
          setAlertType(_.get(resp, "data.status", "error"))
          setAlertMessage(
            `Selected ${lookupType}(s) associated to "${
              selectedHierarchyIds[1] || "-"
            }" ${
              lookupType === "product" ? "category" : "hierarchy"
            } successfully`
          )
          setAlert(true)
          setLoading(false)
        })
        .catch((err) => {})

      setAlert(false)
      setLoading(true)
    }
  }
  useEffect(() => {
    if (isLookUp) {
      if (lookupType === "location") {
        fetchLocationType()
      }
    }
  }, [])

  useEffect(() => {
    setSearchTerm(searchTerm)
  }, [searchTerm])

  useEffect(() => {
    if (lookupType === "location") {
      fetchCityData()
    } else {
      fetchBrandData()
    }
  }, [dropdownDataState.city, dropdownDataState.brand])

  useEffect(() => {
    if (lookupType === "location") {
      fetchStateData()
    } else {
      fetchManufacturerData()
    }
  }, [dropdownDataState.state, dropdownDataState.manufacturer])

  useEffect(() => {
    setDropdownSelectedItems(dropDownSelectedItems)
  }, [dropDownSelectedItems])

  useEffect(() => {
    setDropdownDataState(dropdownDataState)
  }, [dropdownDataState])

  useEffect(() => {
    setDropdownList(dropDownList)
  }, [dropDownList])

  const fetchLocationType = async () => {
    let response = await ServiceAPI.getStoreCommonData(
      API_END_POINTS.getlocationtypes
    )
    let { data = {} } = response || {}
    let locationTypeList = []
    data?.data?.map((o) => locationTypeList.push(o.locationTypeName))
    setlocationTypeList(locationTypeList)
  }

  const fetchCityData = async () => {
    const cityDataState = dropdownDataState.city
    let bodyData = {
      ...cityDataState,
    }
    delete bodyData["showMore"]
    let response = await ServiceAPI.fetchStoreCommonData(
      API_END_POINTS.getAllCities,
      bodyData
    )
    let { data: { data = {} } = {} } = response || {}
    let cityList = []
    data?.docs?.map((o) => cityList.push({ id: o._id, name: o.name }))
    if (cityDataState?.showMore === false) {
      setDropdownList((c) => ({
        ...c,
        city: {
          ...c.city,
          list: cityList,
          totalDocs: data.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    } else {
      let addCityList = dropDownList.city.list
      addCityList.push(...cityList)
      setDropdownList((c) => ({
        ...c,
        city: {
          ...c.city,
          list: addCityList,
          totalDocs: data.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    }
  }

  const fetchStateData = async () => {
    const regionDataState = dropdownDataState.state
    let bodyData = {
      ...regionDataState,
    }
    delete bodyData["showMore"]
    let response = await ServiceAPI.fetchStoreCommonData(
      API_END_POINTS.getAllStates,
      bodyData
    )
    let { data: { data = {} } = {} } = response || {}
    let stateList = []
    data?.docs?.map((o) => stateList.push({ id: o._id, name: o.name }))
    if (regionDataState?.showMore === false) {
      setDropdownList((c) => ({
        ...c,
        state: {
          ...c.state,
          list: stateList,
          totalDocs: data.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    } else {
      let addStateList = dropDownList.state.list
      addStateList.push(...stateList)
      setDropdownList((c) => ({
        ...c,
        state: {
          ...c.state,
          list: addStateList,
          totalDocs: data.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    }
  }

  const fetchBrandData = async () => {
    const brandDataState = dropdownDataState.brand
    let bodyData = {
      ...brandDataState,
    }
    delete bodyData["showMore"]
    let response = await ServiceAPI.postProductCommonData(
      API_END_POINTS.getAllBrands,
      bodyData
    )
    let { data: { data = {} } = {} } = response || {}
    let brandList = []
    data?.docs?.map((o) => brandList.push({ id: o._id, name: o.name }))
    if (brandDataState?.showMore === false) {
      setDropdownList((c) => ({
        ...c,
        brand: {
          ...c.brand,
          list: brandList,
          totalDocs: data.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    } else {
      let addBrandList = dropDownList.brand.list
      addBrandList.push(...brandList)
      setDropdownList((c) => ({
        ...c,
        brand: {
          ...c.brand,
          list: addBrandList,
          totalDocs: data.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    }
  }

  const fetchManufacturerData = async () => {
    const manufacturerDataState = dropdownDataState.manufacturer
    let bodyData = {
      ...manufacturerDataState,
    }
    delete bodyData["showMore"]
    let response = await ServiceAPI.postProductCommonData(
      API_END_POINTS.getAllManufacturers,
      bodyData
    )
    let { data: { data = {} } = {} } = response || {}
    let manufacturersList = []
    data?.docs?.map((o) => manufacturersList.push({ id: o._id, name: o.name }))
    if (manufacturerDataState?.showMore === false) {
      setDropdownList((c) => ({
        ...c,
        manufacturer: {
          ...c.manufacturer,
          list: manufacturersList,
          totalDocs: data.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    } else {
      let addManufacturersList = dropDownList.manufacturer.list
      addManufacturersList.push(...manufacturersList)
      setDropdownList((c) => ({
        ...c,
        manufacturer: {
          ...c.manufacturer,
          list: addManufacturersList,
          totalDocs: data.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    }
  }

  const handleDropdownSearchTerm = (searchVal, label) => {
    setDropdownDataState((c) => ({
      ...c,
      [label.toLowerCase()]: {
        ...c[label.toLowerCase()],
        searchTerm: searchVal,
        showMore: false,
        page: 1,
        limit: 10,
      },
    }))
  }

  const handleDropdownChange = (val, label) => {
    setDropdownSelectedItems((c) => ({ ...c, [label.toLowerCase()]: [...val] }))
  }

  const handleSearchTerm = (val) => {
    setSearchTerm(val)
  }

  const handleShowMore = async (label) => {
    const nxtPage = dropDownList && dropDownList[label.toLowerCase()].nextPage
    setDropdownDataState((c) => ({
      ...c,
      [label.toLowerCase()]: {
        ...c[label.toLowerCase()],
        limit: 10,
        page: nxtPage,
        showMore: true,
      },
    }))
  }

  const handleClear = () => {
    setSearchTerm("")
    setDropdownSelectedItems((c) => ({
      ...c,
      city: [],
      state: [],
      brand: [],
      manufacturer: [],
    }))
    if (sdcFilter)
      setSDCFilter([
        ...sdcFilter,
        {
          field: sdcFilter[0]?.field,
          operator: "eq",
          value: sdcFilter[0]?.value,
        },
      ])
  }

  let searchObj = {}
  if (lookupType === "location") {
    searchObj = {
      dropdown: [
        {
          label: "City",
          list: dropDownList.city.list,
          key: "name",
          filteredItems: dropDownSelectedItems.city,
          totalDocs: dropDownList?.city?.totalDocs,
          searchTerm: dropdownDataState.city.searchTerm,
        },
        {
          label: "State",
          list: dropDownList.state.list,
          key: "name",
          filteredItems: dropDownSelectedItems.state,
          totalDocs: dropDownList.state.totalDocs,
          searchTerm: dropdownDataState.state.searchTerm,
        },
      ],
      searchTerm: searchTerm,
    }
  } else {
    searchObj = {
      dropdown: [
        // {
        //   label: "Brand",
        //   list: dropDownList.brand.list,
        //   key: "name",
        //   filteredItems: dropDownSelectedItems.brand,
        //   totalDocs: dropDownList.brand.totalDocs,
        //   searchTerm: dropdownDataState.brand.searchTerm,
        // },
        // {
        //   label: "Manufacturer",
        //   list: dropDownList.manufacturer.list,
        //   key: "name",
        //   filteredItems: dropDownSelectedItems.manufacturer,
        //   totalDocs: dropDownList.manufacturer.totalDocs,
        //   searchTerm: dropdownDataState.state.searchTerm,
        // },
      ],
      searchTerm: searchTerm,
    }
  }

  const filterSelectedItems = {
    citySelectedItems: dropDownSelectedItems.city,
    stateSelectedItems: dropDownSelectedItems.state,
    brandSelectedItems: dropDownSelectedItems.brand,
    manufacturerSelectedItems: dropDownSelectedItems.manufacturer,
    searchTerm,
  }

  const handleLocationTypeFilter = (val) => {
    setSDCFilter([
      ...sdcFilter,
      { field: "locationType.name", operator: "eq", value: val },
    ])
  }

  let TitleHeading =
    lookupType === "product"
      ? "Product Lookup"
      : lookupType === "storage"
      ? "Storage Lookup"
      : "Store Lookup"
  return (
    <>
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        setReDirect={setReDirect}
        handleCustomCloseRedirect={handleCustomCloseRedirect}
        deleteContent={
          lookupType === "product"
            ? "Do you really want to Disassociate selected Product(s) from the category?"
            : "Do you really want to Disassociate selected Store(s) from the hierarchy?"
        }
      />

      {history?.location?.pathname?.includes("productMapping") ? (
        <div style={{ borderBottom: "1px solid #E0E0E0", width: "100%" }}>
          <Helmet title="Product Mapping" />
          <PageHeaderBreadcrumbKit
            container
            justify="space-between"
            alignContent="center"
            alignItems="center"
            className="PageHeaderBreadcrumbKit"
          >
            <Grid>
              {" "}
              <Adm.Breadcrumb list={BreadcrumbList1} />
            </Grid>
          </PageHeaderBreadcrumbKit>
          <Typography
            style={{
              background: "#ffff",
              fontWeight: 600,
              fontSize: "20px",
              padding: "10px 10px",
            }}
          >
            Product Mapping
          </Typography>
        </div>
      ) : null}
      {history?.location?.pathname?.includes("hierarchyMapping") ? (
        <div style={{ borderBottom: "1px solid #E0E0E0", width: "100%" }}>
          <Helmet title="Stores Mapping" />
          <PageHeaderBreadcrumbKit
            container
            justify="space-between"
            alignContent="center"
            alignItems="center"
            className="PageHeaderBreadcrumbKit"
          >
            <Grid>
              <Adm.Breadcrumb list={BreadcrumbList} />
            </Grid>
          </PageHeaderBreadcrumbKit>
          <Typography
            style={{
              fontWeight: 600,
              background: "#ffff",
              fontSize: "20px",
              padding: "10px 10px",
            }}
          >
            Stores Mapping
          </Typography>
        </div>
      ) : null}

      <div
        style={{
          height: isLookUp ? "" : "",
          overflowY: "auto",
          background: "#fff",
        }}
      >
        <div
          style={{
            ...(isLookUp ? {} : { height: "100%" }),
            border: "0px solid red",
            display: "flex",
            flexGrow: 1,
            flexWrap: "wrap",
            alignContent: "flex-start",
          }}
        >
          {!isLookUp ? null : (
            <Grid
              container
              alignItems="center"
              style={{
                height: 48,
                paddingInline: 16,
                borderBottom: `1px solid #c0c0c0`,
              }}
            >
              <Grid item xs={10}>
                <Typography variant="h4">{TitleHeading}</Typography>
              </Grid>
              <Grid item xs={2} container justify="flex-end">
                <Tooltip title="Close" placement="top">
                  <IconButton size="small" onClick={handleCloseLookUp}>
                    <CloseRounded />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}

          <Adm.BackdropOverlay open={loading} />
          <Grid
            container
            style={{
              height: isLookUp ? "" : "calc(100% - 100px)",
              overflow: "hidden",
            }}
          >
            <Grid
              item
              xs={isLookUp ? 4 : 5}
              style={{
                borderRight: "1px solid #c0c0c0",
                display: toggle ? "block" : isLookUp ? "none" : "",
              }}
            >
              <Hierarchy
                isWriteAllowed={isWriteAllowed}
                lookupType={lookupType}
                isViewMode
                titlePath={titlePath}
                idPath={idPath}
                parentIdPath={parentIdPath}
                canSelectOnlyLastLevel
                isMappingMode
                updateSelectedIds={(ids) => setHierarchySelectedIds(ids)}
              />
            </Grid>
            <Grid
              item
              container
              xs={isLookUp ? (toggle ? 8 : 12) : 7}
              style={{
                flexFlow: "nowrap column",
                paddingRight: toggle ? "20px" : isLookUp ? "50px" : "0px",
                height: "100%",
                borderLeft: toggle ? "" : isLookUp ? "1px solid #e8e8e8" : "",
                marginLeft: toggle ? "" : isLookUp ? "50px" : "",
              }}
            >
              {isLookUp && (
                <Grid
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #EEEEEE",
                    position: "relative",
                  }}
                >
                  <div
                    onClick={handleClick}
                    style={{
                      width: "30px",
                      height: "30px",
                      background: "var(--primaryColor)",
                      position: "absolute",
                      top: "14px",
                      borderRadius: "50%",
                      left: "-40px",
                      cursor: "pointer",
                    }}
                  >
                    {toggle ? (
                      <NavigateBeforeOutlinedIcon
                        style={{ width: "30px", height: "30px", color: "#fff" }}
                      />
                    ) : (
                      <NavigateNextOutlinedIcon
                        style={{ width: "30px", height: "30px", color: "#fff" }}
                      />
                    )}
                  </div>

                  <SearchBar
                    flag={TitleHeading.includes("Store Lookup")}
                    handleClear={handleClear}
                    handleShowMore={handleShowMore}
                    handleSearchTerm={handleSearchTerm}
                    handleDropdownSearchTerm={handleDropdownSearchTerm}
                    dropdownData="State"
                    searchObj={searchObj}
                    handleDropdownChange={handleDropdownChange}
                    handleLocationTypeFilter={handleLocationTypeFilter}
                    lookupType={lookupType}
                    locationTypeList={
                      sdcFilter[0]?.field === "locationType.name" &&
                      sdcFilter[0]?.value?.length > 0
                        ? sdcFilter[0]?.value
                        : locationTypeList
                    }
                  />
                </Grid>
              )}
              {!isLookUp ? (
                <>
                  ={" "}
                  <div
                    className={
                      lookupType === "location"
                        ? "cls-lookup-kit-store"
                        : lookupType === "storage"
                        ? "cls-lookup-kit-storage"
                        : "cls-lookup-kit-product"
                    }
                    style={{
                      minHeight: 300,
                      height: "50%",
                      display: "flex",
                      flexGrow: 1,
                      border: "0px solid red",
                    }}
                  >
                    <Table
                      columns={columns}
                      hierarchyMapping={true}
                      lookupType={lookupType}
                      selectedHierarchyIds={selectedHierarchyIds}
                      triggerDataFetch={triggerDataFetch}
                      setTriggerDataFetch={setTriggerDataFetch}
                      dataFetchTriggerKey="uncategorized"
                      title={
                        lookupType === "location"
                          ? "Uncategorized Store"
                          : lookupType === "storage"
                          ? "Uncategorized Storage"
                          : "Uncategorized Product"
                      }
                      onPrimaryActionClick={(x, cb) =>
                        handleMapping(x, _.get(selectedHierarchyIds, "[0]"), cb)
                      }
                      additionalFilters={[
                        ...SDCFilter,
                        {
                          field:
                            lookupType === "product"
                              ? "product_mapped_category_id"
                              : "locationHierarchy",
                          operator: "eq",
                          value: "",
                        },
                        {
                          field:
                            lookupType === "product" ? "isActive" : "isActive",
                          operator: "eq",
                          value: true,
                        },
                      ]}
                      isInventory={isInventory}
                      hideCheckBoxAll={hideCheckBoxAll}
                      searchObj={searchObj}
                      gridNoRecordsText={
                        lookupType === "location"
                          ? isLookUp
                            ? ""
                            : "No records found"
                          : isLookUp
                          ? ""
                          : "No records found"
                      }
                      isWriteAllowed={isWriteAllowed}
                      preSelectedItems={selectedItems}
                      SDCFilter={SDCFilter}
                      dataUniqueIdPath={dataUniqueIdPath}
                      ShowCheckBox={ShowCheckBox}
                      isKC={isKC}
                      locationBasedProduct={locationBasedProduct}
                    />
                  </div>
                  ={" "}
                </>
              ) : null}
              <div
                className={
                  lookupType === "location"
                    ? isLookUp
                      ? "cls-lookup-kits-stores"
                      : "cls-lookup-kits-stores-associated"
                    : isLookUp
                    ? "cls-lookup-kits-products"
                    : "cls-lookup-kits-products-associated"
                }
                style={{
                  minHeight: "480px",
                  maxHeight: "480px",
                  overflow: "auto",
                  marginBottom: "30px",
                  height: isLookUp ? "100%" : "50%",
                  border: "0px solid red",
                }}
              >
                <Table
                  pageName={pageName}
                  columns={columns}
                  lookupType={lookupType}
                  hierarchyMapping={isLookUp ? false : true}
                  isLookUp={isLookUp}
                  preSelectedItems={selectedItems}
                  triggerDataFetch={triggerDataFetch}
                  setTriggerDataFetch={setTriggerDataFetch}
                  dataFetchTriggerKey="categorized"
                  onPrimaryActionClick={(x, cb) =>
                    isLookUp
                      ? handleLookUpPrimaryAction(x, cb)
                      : disAssociateConformDialog(
                          x,
                          _.get(selectedHierarchyIds, "[0]"),
                          cb
                        )
                  }
                  fetchOnlyOnSelectedIdUpdate
                  selectedHierarchyIds={selectedHierarchyIds}
                  setHierarchySelectedIds={setHierarchySelectedIds}
                  primaryActionLabel={
                    isLookUp
                      ? _.get(props, "primaryActionLabel", "ASSOCIATE")
                      : "DISASSOCIATE"
                  }
                  title={
                    lookupType === "location"
                      ? isLookUp
                        ? "Stores"
                        : "Stores Associated"
                      : isLookUp
                      ? "Products"
                      : "Products Associated"
                  }
                  additionalFilters={[
                    ...SDCFilter,
                    {
                      field:
                        lookupType === "product"
                          ? "product_mapped_category_id"
                          : "locationHierarchy",
                      operator: "eq",
                      value: _.get(
                        selectedHierarchyIds,
                        "[0]",
                        isLookUp ? "" : "*-*"
                      ),
                    },
                    {
                      field: lookupType === "product" ? "isActive" : "isActive",
                      operator: "eq",
                      value: true,
                    },
                  ]}
                  isInventory={isInventory}
                  hideCheckBoxAll={hideCheckBoxAll}
                  searchObj={searchObj}
                  filterSelectedItems={filterSelectedItems}
                  gridNoRecordsText={
                    lookupType === "location"
                      ? isLookUp
                        ? "No records found"
                        : "There are no Stores associated"
                      : isLookUp
                      ? "No records found"
                      : "There are no products associated"
                  }
                  isWriteAllowed={isWriteAllowed}
                  SDCFilter={SDCFilter}
                  dataUniqueIdPath={dataUniqueIdPath}
                  ShowCheckBox={ShowCheckBox}
                  isKC={isKC}
                  locationBasedProduct={locationBasedProduct}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {alert ? (
          <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
        ) : null}
      </div>
    </>
  )
}

export default HierarchyMapping
