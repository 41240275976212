import React from "react"
import * as Adm from "@adm"
import { withStyles } from "@material-ui/core/styles"
import {
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { SearchIcon, CloseIcon } from "@iconsGallery"
import { styled } from "@material-ui/core/styles"
import { DoneIcon } from "@iconsGallery"
import _, { truncate } from "lodash"
import Autocomplete from "@material-ui/lab/Autocomplete"
import AutocompleteDropdown from "../AutocompleteDropdown"

const SearchBox = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "4px 0px 0px 4px",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "var(--primaryColor)",
    },
  },
}))

export const StyledDialog = withStyles((theme) => ({
  paper: {
    minHeight: "40vh !important",
    maxHeight: "40vh !important",
    minWidth: "80vh !important",
    maxWidth: "80vh !important",
    padding: "10px",
  },
}))(Dialog)
const useStyles = makeStyles((theme) => ({}))

const SearchBar = (props) => {
  const {
    flag = false,
    searchObj = {},
    handleDropdownChange = () => {},
    handleSearchTerm = () => {},
    handleDropdownSearchTerm = () => {},
    handleShowMore = () => {},
    handleClear = () => {},
    lookupType = "",
    handleLocationTypeFilter = () => {},
    locationTypeList = [],
  } = props || {}
  const classes = useStyles()
  const [dropdown1, setDropdown1] = React.useState([])
  const [updateData, setUpdateData] = React.useState({ data: [] })
  const [searchVal, setSearchVal] = React.useState(searchObj.searchTerm)
  const [open, setOpen] = React.useState(false)
  let selected_ids = []
  const [fetched_ids] = React.useState(selected_ids)
  const [locType, setLocType] = React.useState("")
  const { t } = useTranslation()
  const onPromptClose = (value) => {
    setOpen(!value)
  }
  React.useEffect(() => {
    setSearchVal(searchVal)
  }, [searchVal])

  React.useEffect(() => {
    setSearchVal(searchObj.searchTerm)
  }, [searchObj])

  const locationTypeDropdownKitAttr = {
    list: locationTypeList,
    defaultValue: locType,
    placeholder: "Type",
    style: { width: "100%" },
    onUpdate: (value) => {
      setLocType(value)
      handleLocationTypeFilter(value)
    },
  }

  return (
    <Grid
      container
      direction="column"
      className={classes.searchBar}
      style={{ paddingLeft: 0 }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
        <div style={{ display: "flex", width: "15vw" }}>
          <SearchBox
            style={{ width: "100vw" }}
            variant="outlined"
            placeholder="Search"
            size="small"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            onKeyDown={(e) => {
              if (e.which === 13) handleSearchTerm(searchVal)
            }}
          />
          <IconButton
            style={{
              backgroundColor: "var(--primaryColor)",
              borderRadius: "0px 4px 4px 0px",
              width: "36px",
              height: "37.5px",
            }}
            size="small"
            variant="outlined"
            onClick={() => handleSearchTerm(searchVal)}
          >
            <SearchIcon style={{ color: "white" }} />
          </IconButton>
        </div>

        {flag === false && (
          <>
            {lookupType === "location" && (
              <div style={{ width: "12vw" }}>
                <Adm.DropdownKit {...locationTypeDropdownKitAttr} />
              </div>
            )}
          </>
        )}
        {searchObj?.dropdown.map((o, index) => {
          return (
            <AutocompleteDropdown
              key={index}
              label={o.label}
              data={o.list}
              filteredItems={o.filteredItems}
              searchTerm={o.searchTerm}
              handleDropdownChange={handleDropdownChange}
              handleSearchTerm={handleDropdownSearchTerm}
              totalDocs={o.totalDocs}
              handleShowMore={handleShowMore}
            />
          )
        })}
        <Button>
          <Typography
            color="primary"
            style={{ color: "var(--primaryColor)" }}
            onClick={() => {
              setLocType("")
              handleClear()
            }}
          >
            Clear
          </Typography>
        </Button>
      </div>
      <StyledDialog
        open={open}
        onClose={() => {
          onPromptClose(true)
        }}
      >
        <DialogContent>
          <div
            style={{
              margin: "0px",
              position: "absolute",
              top: "40%",
              left: "30%",
            }}
          >
            <Typography>
              <b>Are you sure you want to cancel?</b>
            </Typography>
            <Typography>
              <b>Any usaved changes will be lost.</b>{" "}
            </Typography>
            <Grid
              container
              direction="row"
              style={{ marginTop: "3em", marginLeft: "2em" }}
            >
              <Button
                variant="outlined"
                style={{ marginRight: "3em" }}
                onClick={() => {
                  onPromptClose(true)
                }}
              >
                No
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  onPromptClose(true)
                }}
                style={{ color: "#FFFFFF", backgroundColor: "#1565C0" }}
              >
                Yes
              </Button>
            </Grid>
          </div>
        </DialogContent>
      </StyledDialog>
    </Grid>
  )
}
export default SearchBar
export { SearchBar }
